"use strict";

import { ValidationObserver } from "vee-validate";

import collection from "@/settings/collection.js";
import field from "@/settings/field.js";
import management from "@/settings/management.js";
import textset from "@/settings/textset.js";

import Button from "@/components/Button.vue";
import Input from "@/components/Input.vue";
import InputFile from "@/components/InputFile.vue";
import Modal from "@/components/Modal.vue";

import firebase from "@/mixins/firebase.js";
import modal from "@/mixins/modal.js";
import storage from "@/mixins/storage.js";

export default {
    "components": {
        ValidationObserver,
        Button,
        Input,
        InputFile,
        Modal,
    },
    "mixins": [
        firebase,
        modal,
        storage,
    ],
    data() {
        return {
            "collection": [],
            "collectionName": "",
            "disabled": false,
            "field": field,
            "file": [],
            "fileIndex": [],
            "flagValidatedImmediate": false,
            "input": {},
            "invalid": true,
            "management": {},
            "readData": {},
            "textset": textset,
        };
    },
    "methods": {
        $_created() {
            this.$_reset();
            this.$_setData();
            if (this.editMode === "create" || !this.editMode) {
                this.flagValidatedImmediate = true;
            }
            else {
                if (this.editMode === "detail") {
                    this.disabled = true;
                }
                const doc = this.id;
                return this.$_read(doc).catch(error => {
                    console.error(error.message);
                    this.$_modalError("read");
                });
            }    
        },
        $_reset() {
            this.management = management[this.name];
            const collectionName = this.management.collection;
            const collectionGroupName = this.management.collectionGroup ? this.management.collectionGroup : "";
            this.collectionName = collectionGroupName ? `${collectionName}/${collectionGroupName}` : collectionName;
            this.collection = collection[this.collectionName];
        },
        // 作成
        $_create(doc) {
            const fileProcessData = {
                "file": this.file,
                "fileName": this.input.image,
            };
            const fileProcess = [
                this.$_fileUpload(fileProcessData),
            ];
            return Promise.all(fileProcess).then(() => {
                return this.$_firestoreCreate({
                    "collection": this.collectionName,
                    "doc": doc,
                    "set": this.input,
                });
            });
        },
        // 取得
        $_read(doc) {
            return this.$_firestoreRead({
                "collection": this.collectionName,
                "doc": doc,
            }).then(response => {
                const responseData = response.data();
                this.input = {
                    ...this.input,
                    ...responseData,
                };
                this.readData = JSON.parse(JSON.stringify(this.input));
                const fileProcessData = {
                    "fileName": this.input.image,
                };
                const filePath = this.$_fileDownload(fileProcessData);
                if (filePath) {
                    filePath.then(response => {
                        const filePath = response;
                        for (const index in filePath) {
                            this.file[index].option.thumbnail.path = filePath[index];
                        }
                    });
                }
            });
        },
        // 更新
        $_update(doc) {
            const fileProcessData = {
                "file": this.file,
                "fileName": this.input.image,
            };
            const fileProcess = [
                this.$_fileUpload(fileProcessData),
                this.$_fileDelete(fileProcessData),
            ];
            return Promise.all(fileProcess).then(() => {
                for (const index in this.file) {
                    const deleteFlag = this.file[index].deleteFlag;
                    if (deleteFlag === "1") {
                        this.input.image[index] = "";
                    }
                }
                return this.$_firestoreUpdate({
                    "collection": this.collectionName,
                    "doc": doc,
                    "set": this.input,
                });
            });
        },
        // 削除
        $_delete(doc) {
            if (this.management.delete === false && !this.history) {
                this.input.deletedAt = this.$_firestoreTimestamp();
                const completed = this.management.completed;
                if (completed) {
                    this.input[completed] = this.$_firestoreTimestamp();
                }
                return this.$_update(doc);
            }
            else {
                const fileProcessData = {
                    "file": this.file,
                    "fileName": this.input.image,
                };
                const fileProcess = [
                    this.$_fileDelete(fileProcessData, "all"),
                ];
                return Promise.all(fileProcess).then(() => {
                    return this.$_firestoreDelete({
                        "collection": this.collectionName,
                        "doc": doc,
                    });
                });
            }
        },
        $_setData() {
            const collection = this.collection;
            let fileIndex = 0;
            for (const index in collection) {
                const key = collection[index].field;
                if (key !== "image") {
                    if (!this.input.key) {
                        const value = this.field[key].type !== "checkbox" ? "" : [];
                        this.$set(this.input, key, value);
                    }
                }
                else {
                    if (!this.input.image) {
                        this.input.image = [];
                    }
                    this.$set(this.fileIndex, index, fileIndex);
                    this.$set(this.input.image, fileIndex, "");
                    this.$set(this.file, fileIndex, {
                        "data": "",
                        "deleteFlag": "0",
                        "option": {
                            "accept": ".gif, .jpg, .jpeg, .png",
                            "id": String(fileIndex),
                            "thumbnail": {
                                "path": "",
                            },
                        },
                    });
                    fileIndex += 1;
                }
            }
            // inputにuserIdがある場合はpropsのidを代入する
            if (Object.keys(this.input).indexOf("userId") !== -1) {
                this.input.userId = this.id[0];
            }
            this.readData = JSON.parse(JSON.stringify(this.input));
        },
        $_setFileName(index, fileName) {
            if (!this.input.image[index]) {
                this.input.image[index] = fileName;
            }
        },
    },
};
