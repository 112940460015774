"use strict";

export default {
    "closedDates": [
        {
            "field": "closedDate",
            "required": true,
        },
    ],
    "drivers": [
        {
            "field": "name",
            "required": true,
        },
    ],
    "items": [
        {
            "field": "itemNumber",
            "required": true,
        },
        {
            "field": "itemName",
            "required": true,
        },
        {
            "field": "itemDescription",
            "required": true,
        },
        {
            "field": "price",
            "required": true,
        },
        {
            "field": "stock",
            "required": true,
        },
        {
            "field": "freeText",
            // "required": true,
        },
        {
            "field": "image",
            "required": true,
        },
        {
            "field": "image",
            // "required": true,
        },
        {
            "field": "image",
            // "required": true,
        },
    ],
    "orders": [
        {
            "field": "name",
            "required": true,
        },
        {
            "field": "tel",
            "required": true,
        },
        {
            "field": "postalCode",
            "required": true,
        },
        {
            "field": "address",
            "required": true,
        },
        {
            "field": "mailAddress",
            "required": true,
        },
        {
            "field": "comment",
            // "required": true,
        },
        {
            "field": "paymentMethod",
            "required": true,
        },
        {
            "field": "receipt",
            // "required": true,
        },
        {
            "field": "receiptAddress",
            // "required": true,
        },
        {
            "field": "receiptDescription",
            // "required": true,
        },
        {
            "field": "receiptInclude",
            // "required": true,
        },
        {
            "field": "sendName",
            // "required": true,
        },
        {
            "field": "sendTel",
            // "required": true,
        },
        {
            "field": "sendPostalCode",
            // "required": true,
        },
        {
            "field": "sendAddress",
            // "required": true,
        },
    ],
    "receptionTimes": [
        {
            "field": "startTime",
        },
        {
            "field": "endTime",
        },
    ],
    "users": [
        {
            "field": "name",
            // "required": true,
        },
        // {
        //     "field": "gender",
        //     "required": true,
        // },
        {
            "field": "tel",
            "required": true,
        },
        {
            "field": "memo",
            // "required": true,
        },
        // {
        //     "field": "postalCode",
        //     // "required": true,
        // },
        // {
        //     "field": "address",
        //     // "required": true,
        // },
    ],
    "users/items": [
        {
            "field": "userId",
            "required": true,
        },
        {
            "field": "receptionDate",
            "required": true,
        },
        {
            "field": "image",
            "required": true,
        },
        {
            "field": "freeText",
            // "required": true,
        },
        {
            "field": "reservationDate",
            // "required": true,
        },
        {
            "field": "reservationTime",
            // "required": true,
        },
        {
            "field": "orderDatetime",
            // "required": true,
        },
        {
            "field": "returnDatetime",
            // "required": true,
        },
    ],
};
