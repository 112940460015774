<template>
    <div>
        <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
            class="dashboard"
            tag="div"
        >
            <h1>
                {{ $_pagetitle }}
            </h1>
            <div v-for="(data, key, index) in week" :key="`week${index}`" :class="$style.detailInner" class="l-3-10-m--all">
                <div :class="$style.weekName">
                    <h2>
                        {{ data }}曜日
                    </h2>
                </div>
                <div v-for="(data, index) in collection" :key="`week${index}`">
                    <h3 :key="`head${index}`" :class="$style.detailHead" class="isLeft">
                        {{ field[data.field].head }}
                    </h3>
                    <div :key="`div${index}`">
                        <Input
                            :data="data"
                            :disabled="disabled"
                            :flag-validated-immediate="flagValidatedImmediate"
                            :input="readData[key][data.field]"
                            @input="input[key][data.field] = $event"
                        />
                    </div>
                </div>
            </div>
            <div class="l-2-00-m--all" :class="$style.buttonArea">
                <div>
                    <Button
                        v-if="editMode !== 'detail'"
                        :disabled="invalid"
                        type="next"
                        @action="buttonNext()"
                    />
                </div>
                <div>
                </div>
            </div>
        </ValidationObserver>
        <Modal
            :modal="modal"
        />
    </div>
</template>

<script>
    "use strict";

    import dashboard from "@/mixins/dashboard.js";
    import detail from "@/mixins/detail.js";

    export default {
        "mixins": [
            dashboard,
            detail,
        ],
        "props": {
            "name": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "editMode": "update",
                "id": [
                    "week",
                ],
                "input": {
                    "mon": {
                        "startTime": "",
                        "endTime": "",
                    },
                    "tue": {
                        "startTime": "",
                        "endTime": "",
                    },
                    "wed": {
                        "startTime": "",
                        "endTime": "",
                    },
                    "thu": {
                        "startTime": "",
                        "endTime": "",
                    },
                    "fri": {
                        "startTime": "",
                        "endTime": "",
                    },
                    "sat": {
                        "startTime": "",
                        "endTime": "",
                    },
                    "sun": {
                        "startTime": "",
                        "endTime": "",
                    },
                },
                "week": {
                    "mon": "月",
                    "tue": "火",
                    "wed": "水",
                    "thu": "木",
                    "fri": "金",
                    "sat": "土",
                    "sun": "日",
                },
            };
        },
        created() {
            this.$_reset();
            this.readData = this.input;
            this.$_read(this.id).catch(error => {
                console.error(error.message);
                this.$_modalError("read");
            });
        },
        "methods": {
            buttonNext() {
                const title = this.management.title;
                this.$_modalOpen({
                    "data": {
                        "message": {
                            "start": `${title}を変更しますか？`,
                            "end": `${title}を変更しました！`,
                            "error": `${title}の変更に失敗しました。`,
                        },
                        "button": {
                            "primary": "変更する",
                            "cancel": "キャンセル",
                            "finished": "閉じる",
                            "error": "閉じる",
                        },
                    },
                    "editMode": "create",
                    "callback": {
                        "start": () => {
                            const doc = this.id;
                            this.$_update(doc).then(() => {
                                this.$_modalFinished();
                            }).catch(error => {
                                console.error(error.message);
                                this.$_modalError("update");
                            });
                        },
                        "end": () => {
                            this.$_modalClose();
                        },
                    },
                });
            },
        },
    };
</script>

<style lang="scss" module>
    .weekName {
        width: 120px !important;
    }
    .buttonArea {
        margin: 72px 0 0;
    }
    .detailHead {
        margin: 0 0 8px !important;
    }
    .detailInner {
        margin: 40px 0 0;
        width: 480px;
    }
</style>
