"use strict";

export default {
    "computed": {
        $_pagetitle() {
            const title = this.management.title;
            const textset = this.textset[this.management.textset];
            const editMode = this.editMode;
            return `${title}${textset[editMode]}`;
        },
    },
    "methods": {
        $_link(type, name) {
            if (type === "list") {
                if (name) {
                    return {
                        "name": `${name}_list`,
                    };
                }
                else {
                    if (!this.history) {
                        return {
                            "name": `${this.name}_list`,
                        };
                    }
                    else {
                        return {
                            "name": `${this.name}_history_list`,
                        };
                    }
                }
            }
            else if (type === "create") {
                return {
                    "name": `${name}_create`,
                };
            }
            else if (type === "update") {
                return {
                    "name": `${name}_update`,
                };
            }
        },
        // 戻るボタン
        $_buttonBack(name) {
            this.$router.push(this.$_link("list", name));
        },
        // 次へボタン
        $_buttonNext() {
            const modalOpenData = this.$_modalReset(this.editMode);
            modalOpenData.callback.start = () => {
                if (this.editMode === "create") {
                    const doc = this.id;
                    this.$_create(doc).then(() => {
                        this.$_modalFinished();
                    }).catch(error => {
                        console.error(error.message);
                        this.$_modalError("create");
                    });
                }
                else {
                    const doc = this.id;
                    this.$_update(doc).then(() => {
                        this.$_modalFinished();
                    }).catch(error => {
                        console.error(error.message);
                        this.$_modalError("update");
                    });
                }
            };
            modalOpenData.callback.end = () => {
                this.$router.push(this.$_link("list", this.name));
            };
            this.$_modalOpen(modalOpenData);
        },
        // 削除ボタン
        $_buttonDelete() {
            const modalOpenData = this.$_modalReset("delete");
            modalOpenData.callback.start = () => {
                const doc = this.id;
                this.$_delete(doc).then(() => {
                    this.$_modalFinished();
                }).catch(error => {
                    console.error(error.message);
                    this.$_modalError("delete");
                });
            };
            modalOpenData.callback.end = () => {
                this.$router.push(this.$_link("list", this.name));
            };
            this.$_modalOpen(modalOpenData);
        },
        // モーダル
        $_modal(data) {
            const title = this.management.title;
            const textset = this.textset[this.management.textset];
            const text = textset.status[data.type];
            const datetime = `${data.type}Datetime`;
            let modalOpenData = {};
            if (data.type === "return") {
                const doc = [data.itemData.id.userId, data.itemData.id.updateId];
                const set = {};
                modalOpenData = {
                    "data": {
                        "message": {
                            "start": `${title}を${text}にしますか？`,
                            "end": `${text}にしました！`,
                            "error": `${title}の${text}に失敗しました。`,
                        },
                        "button": {
                            "primary": `${text}にする`,
                            "cancel": "キャンセル",
                            "finished": "閉じる",
                            "error": "閉じる",
                        },
                    },
                    "callback": {
                        "start": () => {
                            set[datetime] = this.$_firestoreTimestamp();
                            this.$_firestoreUpdate({
                                "collection": data.collection,
                                "doc": doc,
                                "set": set,
                            }).then(() => {
                                this.$_read();
                                this.$_modalFinished();
                            }).catch(() => {
                                this.$_modalError();
                            });
                        },
                        "end": () => {
                            this.$_modalClose();
                        },
                    },
                };
            }
            this.$_modalOpen(modalOpenData);
        },
    },
};
