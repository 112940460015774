<template>
    <div>
        <ValidationProvider
            ref="provider"
            v-slot="{ errors }"
            :name="`${head}${Number(option.id) + 1}`"
            :rules="rules"
            :immediate="flagValidatedImmediate"
        >
            <input
                v-if="!disabled"
                type="file"
                :accept="option.accept"
                @change="selectedFile(arguments[0])"
            >
            <p
                v-show="errors[0]"
                class="errorMessage"
            >
                {{ errors[0] }}
            </p>
        </ValidationProvider>
        <div v-if="input.file">
            <div>
                <img :class="$style.image" :src="thumbnail.path" :width="thumbnail.image.width" :height="thumbnail.image.height" :alt="thumbnail.image.alt">
            </div>
        </div>
        <div v-else-if="option.thumbnail.path">
            <div>
                <img :class="$style.image" :src="option.thumbnail.path" :width="thumbnail.image.width" :height="thumbnail.image.height" :alt="thumbnail.image.alt">
            </div>
            <div v-if="!disabled && !data.required">
                <input :id="`deleteFlag${option.id}`" v-model="deleteFlag" type="checkbox" true-value="1" false-value="0">
                <label :for="`deleteFlag${option.id}`">
                    このファイルを削除する
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    "use strict";

    import { extend, ValidationProvider } from "vee-validate";
    import * as rules from "vee-validate/dist/rules";
    import ja from "vee-validate/dist/locale/ja.json";

    import validate from "@/validate.js";

    import field from "@/settings/field.js";

    for (const rule in rules) {
        extend(rule, {
            ...rules[rule],
            "message": ja.messages[rule],
        });
    }

    for (const key in validate) {
        extend(`${key}`, validate[key]);
    }

    export default {
        "components": {
            ValidationProvider,
        },
        "props": {
            "data": {
                "type": Object,
                "required": true,
            },
            "disabled": {
                "type": Boolean,
                "required": false,
            },
            "flagValidatedImmediate": {
                "type": Boolean,
                "required": true,
            },
            "option": {
                "type": Object,
                "required": true,
            },
        },
        data() {
            return {
                "head": "",
                "input": {
                    "deleteFlag": "0",
                    "file": "",
                },
                "rules": "",
                "thumbnail": {
                    "image": {
                        "alt": "",
                        "height": "",
                        "width": "",
                    },
                    "path": "",
                },
            };
        },
        "computed": {
            "deleteFlag": {
                get() {
                    return this.input.deleteFlag;
                },
                set(val) {
                    this.$emit("delete-flag", val);
                    return this.input.deleteFlag = val;
                },
            },
        },
        "watch": {
            "option.thumbnail.path": function() {
                if (this.option.thumbnail.path) {
                    this.$refs.provider.validate(true);
                }
            },
        },
        created() {
            const fieldName = this.data.field;
            const fieldData = field[fieldName];
            const rules = fieldData.rules;
            this.head = fieldData.head;
            this.rules = this.data.required ? rules ? `${rules}|required`: "required" : rules;
        },
        mounted() {
            this.$refs.provider.validate();
        },
        "methods": {
            selectedFile(e) {
                e.preventDefault();
                const file = e.target.files[0];
                this.$refs.provider.validate(file);
                let fileName = "";
                if (file) {
                    fileName = this.createFileName(file.name);
                    this.thumbnail.path = URL.createObjectURL(file);
                }
                this.input.file = file;
                this.$emit("data", file);
                this.$emit("file-name", fileName);
            },
            createFileName(filesName) {
                const strong = 1000;
                const name = new Date().getTime().toString(16) + Math.floor(strong * Math.random()).toString(16);
                const extension = this.getExtension(filesName);
                return `${name}.${extension}`;
            },
            getExtension(string) {
                const extension = string.split(".").slice(-1)[0].toLowerCase();
                return extension;
            },
        },
    };
</script>

<style lang="scss" module>
    .image {
        margin: 8px 0;
    }
</style>
